import _ from "lodash"
import dayjs from "dayjs"
import { getMinGradeNumber } from "../../utilities/grades"
import { genderOptions } from "../../config/user"

export const cleanUser = ({ user, curShoesTag }) => {
  const userToSave = _.chain(user)
    .cloneDeep()
    .pick([
      "_id",
      "slug",
      "name",
      "firstName",
      "lastName",
      "email",
      "slug",
      "isFrenchGrades",
      "isPrivate",
      "profile.picture",
      "profile.gender",
      "profile.location",
      "profile.website",
      "profile.cover",
      "profile.bio",
      "profile.height",
      "profile.weight",
      "profile.age",
      "profile.shoes",
      "profile.harness",
      "profile.rope",
      "profile.dob",
      "isAllowingDataToGym",
      "isGymNotificationsEnabled",
      "isCommentSubscriptionDisabled",
      "unavailableWorkDays",
      "isHidingInSettingSchedule",
      "isSetterNotificationsEnabled",
      "totalHoursAvailablePerWeek",
      "isDisablingKeepAwake",
    ])
    .value()
  // not the best way, but need to revisit tag component to maybe roll my own
  if (curShoesTag) {
    userToSave.profile.shoes = [
      ...(userToSave.profile.shoes || []),
      curShoesTag,
    ]
  }
  userToSave.profile.height = Number(userToSave.profile?.height ?? 0)
  userToSave.profile.weight = Number(userToSave.profile?.weight ?? 0)
  return userToSave
}

export const cleanProblem = ({ problem }) => {
  let cleanProblem = _.chain(problem)
    .pick([
      "_id",
      "name",
      "slug",
      "version",
      "grade",
      "gradeName",
      "setter",
      "location",
      "zoom",
      "description",
      "picture",
      "beta",
      "quality",
      "isNameEditable",
      "numberOfSignificantMoves",
      "totalAscents",
      "popularity",
      "height",
      "start",
      "landing",
      "crimpy",
      "slopey",
      "pinchy",
      "juggy",
      "pockety",
      "heelhooks",
      "toehooks",
      "bigFeet",
      "smallFeet",
      "compression",
      "tension",
      "coordination",
      "dynamic",
      "static",
      "slab",
      "vertical",
      "overhang",
      "steep",
      "roof",
      "arete",
      "dihedral",
      "kneebar",
      "sun",
      "crack",
      "areaId",
      "guideId",
      "canvasFile",
      "oembed3d",
      "gymId",
      "gymAreaId",
      "isDraft",
      "isArchived",
      "color",
      "startingHoldPicture",
      "arBetas",
      "image",
      "youtubeId",
      "videoLink",
      "undercling",
      "technical",
      "customCharacteristics",
    ])
    .value()
  cleanProblem = _.omit(cleanProblem, ["location.__typename"])
  cleanProblem.setter =
    problem.setter?._id || problem.setter || problem.setterId
  if (problem.oembed3d) {
    cleanProblem.oembed3d = _.pick(problem.oembed3d, [
      "url",
      "thumbnailUrl",
      "html",
    ])
  }
  cleanProblem.arBetas =
    cleanProblem.arBetas &&
    cleanProblem.arBetas.map(({ picture, description }) => ({
      picture,
      description,
    }))
  return cleanProblem
}

export const cleanProblemChars = (problem) => {
  return _.pick(problem, [
    "_id",
    "height",
    "start",
    "landing",
    "crimpy",
    "slopey",
    "pinchy",
    "juggy",
    "pockety",
    "heelhooks",
    "toehooks",
    "bigFeet",
    "smallFeet",
    "compression",
    "tension",
    "coordination",
    "dynamic",
    "static",
    "slab",
    "vertical",
    "overhang",
    "steep",
    "roof",
    "arete",
    "dihedral",
    "kneebar",
    "sun",
    "crack",
    "undercling",
    "technical",
    "customCharacteristics",
  ])
}

export const cleanRoute = ({ route }) => {
  let cleanRoute = _.chain(route)
    .pick([
      "_id",
      "name",
      "slug",
      "location",
      "description",
      "isNameEditable",
      "gear",
      "picture",
      "grade",
      "gradeName",
      "beta",
      "quality",
      "popularity",
      "height",
      "zoom",
      "pitches",
      "numberOfBolts",
      "version",
      "protection",
      "safety",
      "sun",
      "kneebar",
      "pumpy",
      "powerful",
      "crimpy",
      "slopey",
      "pinchy",
      "juggy",
      "pockety",
      "bigFeet",
      "smallFeet",
      "compression",
      "tension",
      "dynamic",
      "static",
      "coordination",
      "lead",
      "toprope",
      "autobelay",
      "slab",
      "vertical",
      "overhang",
      "steep",
      "roof",
      "arete",
      "dihedral",
      "crack",
      "areaId",
      "guideId",
      "canvasFile",
      "gymId",
      "gymAreaId",
      "isDraft",
      "isArchived",
      "color",
      "startingHoldPicture",
      "arBetas",
      "undercling",
      "technical",
      "heelhooks",
      "customCharacteristics",
    ])
    .value()
  cleanRoute = {
    ...cleanRoute,
    pitches: cleanRoute?.pitches?.map((pitch) =>
      _.pick(pitch, [
        "name",
        "grade",
        "gear",
        "description",
        "numberOfBolts",
        "height",

        "kneebar",
        "pumpy",
        "powerful",
        "crimpy",
        "slopey",
        "pinchy",
        "juggy",
        "pockety",
        "bigFeet",
        "smallFeet",
        "compression",
        "tension",
        "dynamic",
        "static",
        "slab",
        "vertical",
        "overhang",
        "steep",
        "roof",
        "arete",
        "dihedral",
        "crack",
      ]),
    ),
  }

  cleanRoute = _.omit(cleanRoute, [
    "location.__typename",
    "oembed3d.__typename",
  ])
  cleanRoute = _.omit(cleanRoute, ["location.__typename"])
  cleanRoute.setter = route.setter?._id || route.setter || route.setterId
  if (route.oembed3d) {
    cleanRoute.oembed3d = _.pick(route.oembed3d, [
      "url",
      "thumbnailUrl",
      "html",
    ])
  }
  cleanRoute.arBetas =
    cleanRoute.arBetas &&
    cleanRoute.arBetas.map(({ picture, description }) => ({
      picture,
      description,
    }))
  // number of bolts must be an int
  if (!cleanRoute.numberOfBolts) {
    cleanRoute.numberOfBolts = 0
  }
  return cleanRoute
}

export const cleanRouteChars = (route) => {
  return _.pick(route, [
    "_id",
    "protection",
    "safety",
    "sun",
    "kneebar",
    "pumpy",
    "powerful",
    "crimpy",
    "slopey",
    "pinchy",
    "juggy",
    "pockety",
    "bigFeet",
    "smallFeet",
    "compression",
    "tension",
    "dynamic",
    "static",
    "coordination",
    "slab",
    "vertical",
    "overhang",
    "steep",
    "roof",
    "arete",
    "dihedral",
    "crack",
    "undercling",
    "technical",
    "heelhooks",
    "toprope",
    "lead",
    "autobelay",
    "customCharacteristics",
  ])
}

export const cleanSend = ({
  send,
  isEditing,
  loggedInUser,
  feature,
  timezone,
}) => {
  let sendToSave = _.chain(send)
    .pick([
      "_id",
      "name",
      "sendType",
      "slug",
      "date",
      "description",
      "grade",
      "tries",
      "isOnsight",
      "isFirstAscent",
      "isGreatest",
      "comments",
      "likes",
      "shoes",
      "harness",
      "rope",
      "isIndoor",
      "isGradeSet",
      "isGrade",
      "location",
      "competitorId",
      "compSetId",
    ])
    .value()

  sendToSave = _.omit(sendToSave, ["location.__typename"])
  if (isEditing) {
    sendToSave = _.omit(sendToSave, ["media", "type"])
  }
  sendToSave.tries = Number(sendToSave.tries) || 1
  sendToSave.tries = Number(sendToSave.tries) || 1
  // set climb to either passed id or id if object is in send
  sendToSave.routeId =
    feature && feature.type === "route"
      ? feature._id
      : send.route
      ? send.route._id
      : send.routeId
  sendToSave.problemId =
    feature && feature.type === "problem"
      ? feature._id
      : send.problem
      ? send.problem._id
      : send.problemId
  // set climb grade and send name if passed in (from NewSend)
  if (feature) {
    sendToSave.grade = sendToSave.grade || feature.grade
    sendToSave.name =
      sendToSave.name ||
      (loggedInUser ? `${loggedInUser.firstName} sent ${feature.name}` : "")
    sendToSave.sendType = send.sendType || feature.type
  }
  // add timezone so can check habit have had issues in past wtih android
  // not working with dayjs, so leaving try catch here just in case
  try {
    sendToSave.timezone = timezone || dayjs.tz.guess()
  } catch (e) {
    sendToSave.timezone = "America/Denver"
  }
  return sendToSave
}

export const cleanSchedule = (schedule) => {
  const cleanSchedule = _.pick(schedule, [
    "_id",
    "isAbleToDuplicate",
    "settingDay",
    "task",
    "numSetters",
    "hoursToSet",
  ])
  cleanSchedule.setters = _.uniq((schedule.setters || []).map((set) => set._id))
  cleanSchedule.gymArea = schedule?.gymArea?._id
  cleanSchedule.gym = schedule?.gym?._id
  return cleanSchedule
}

export const cleanWorkout = (workout) => {
  const cleanWorkout = _.pick(workout, [
    "_id",
    "name",
    "description",
    "isPublic",
    "isFeatured",
    "taggedCharacteristics",
    "isCharCircuit",
    "circuitType",
    "image",
    "youtubeId",
    "videoLink",
    "isSendingPush",
  ])
  cleanWorkout.creator = workout?.creator?._id
  cleanWorkout.gym = workout?.gym?._id
  cleanWorkout.guide = workout?.guide?._id
  const cleanExercises = workout.exercises.map(
    ({
      name,
      description,
      repetitions,
      duration,
      restTime,
      problem,
      route,
      exerciseType,
      climbType,
      grade,
      youtubeId,
      videoLink,
      videoExerciseTypes,
      image,
    }) => {
      return {
        name,
        description,
        repetitions,
        duration,
        restTime,
        problem: problem?._id,
        route: route?._id,
        exerciseType,
        climbType,
        grade,
        youtubeId,
        videoLink,
        videoExerciseTypes,
        image,
      }
    },
  )
  cleanWorkout.exercises = cleanExercises
  return cleanWorkout
}

export const cleanHabit = (habit, gradeType) => {
  let habitToSave = _.pick(habit, [
    "_id",
    "slug",
    "name",
    "description",
    "minGrade",
    "minGradeColor",
    "minGradeName",
    "climbType",
    "numClimbs",
    "reminderDaysOfWeek",
    "reminderTimesOfDay",
    "isActive",
    "isReminderOn",
    "goalType",
    "metersToClimb",
    "scheduleType",
  ])
  habitToSave.numClimbs = Number(habit.numClimbs)
  habitToSave.minGrade = Number(habit.minGrade)
  habitToSave.metersToClimb = Number(habit.metersToClimb)
  habitToSave.reminderTimesOfDay = habit.reminderTimesOfDay.map((rT) =>
    _.omit(rT, ["__typename"]),
  )
  // if workout, null out other values
  if (habit.workout) {
    habitToSave = {
      ...habitToSave,
      workout: habit?.workout?._id,
      minGrade: null,
      climbType: null,
      numClimbs: null,
    }
  }
  // get min grade of grade value
  habitToSave.minGrade = habit.minGrade
    ? getMinGradeNumber({
        grade: habit.minGrade,
        climbType: habit.climbType,
        gym: habit.gym,
        gradeType,
      })
    : 0
  if (habit.gym) {
    habitToSave = {
      ...habitToSave,
      gym: habit?.gym?._id,
    }
  }

  return habitToSave
}

export const cleanArea = ({ area, isEditing }) => {
  let areaToSave = _.chain(area)
    .pick([
      "_id",
      "isNameEditable",
      "name",
      "slug",
      "description",
      "directions",
      "access",
      "problemIds",
      "routeIds",
      "areaIds",
      "createDate",
      "version",
      "location",
    ])
    .value()
  areaToSave = _.omit(areaToSave, ["location.__typename"])
  areaToSave.parentArea = area?.parentArea?._id || area?.parentArea
  areaToSave.guide = area?.guide?._id || area?.guide
  return areaToSave
}

export const cleanGuide = ({ guide, isEditing }) => {
  let guideToSave = _.chain(guide)
    .pick([
      "_id",
      "name",
      "slug",
      "description",
      "directions",
      "access",
      "router",
      "camping",
      "country",
      "region",
      "woeid",
      "problemIds",
      "routeIds",
      "areaIds",
      "gradeSystem",
      "createDate",
      "version",
      "location",
    ])
    .value()
  // remove prob and routes if editing
  // if (isEditing) {
  //   guideToSave = _.chain(guideToSave)
  //     .omit(["problemIds", "routeIds", "areaIds"])
  //     .value()
  // }
  guideToSave = _.omit(guideToSave, ["location.__typename"])
  return guideToSave
}

export const cleanAttempt = ({ attempt, loggedInUser }) => {
  const attemptToSave = _.pick(attempt, [
    "problem",
    "route",
    "date",
    "grade",
    "climbType",
    "isGrade",
    "user",
  ])
  attemptToSave.user = attemptToSave.user || loggedInUser._id
  attemptToSave.problem = attempt.problem?._id
  attemptToSave.route = attempt.route?._id
  return attemptToSave
}

export const cleanTeam = (team) => {
  let teamToSave = _.chain(team)
    .pick([
      "_id",
      "name",
      "slug",
      "compId",
      "picture",
      "category",
      "canvasFile",
      "isAllowingFloaters",
    ])
    .value()
  teamToSave.timeSlotId = team.timeSlotId || team.timeSlot?._id
  return teamToSave
}

export const cleanCompetitor = (competitor) => {
  let competitorToSave = _.chain(competitor)
    .pick([
      "_id",
      "name",
      "userId",
      "compId",
      "teamId",
      "category",
      "maxProblemGrade",
      "maxRouteGrade",
      "competitorAnswers",
      "competitorBonusActivities",
      "isCheckedIn",
      "discountedPriceId",
    ])
    .value()
  competitorToSave.timeSlotId =
    competitor.timeSlotId || competitor?.timeSlot?._id
  competitorToSave.compId = competitor?.comp?._id || competitor.compId
  competitorToSave.teamId = competitor?.team?._id || competitor.teamId
  competitorToSave.referredUserId = competitor?.referredUser?._id
  competitorToSave.competitorAnswers = competitor?.competitorAnswers
    ?.filter((ans) => !!ans.questionId)
    ?.map(({ questionId, answer }) => ({ questionId, answer }))
  competitorToSave.competitorBonusActivities =
    competitor?.competitorBonusActivities?.map((act) => ({
      compSet: act.compSet?._id,
      activity: act.activity?._id,
    }))
  return competitorToSave
}

export const cleanGymGroup = (group, isEditing) => {
  let gymGroupToSave = _.chain(group).pick(["_id", "name", "gyms"]).value()
  return gymGroupToSave
}

export const cleanGymArea = (gymArea) => {
  // sigh... removing the typenames
  gymArea.problemConfig = _.omit(gymArea.problemConfig, "__typename")
  gymArea.routeConfig = _.omit(gymArea.routeConfig, "__typename")

  gymArea.distribution = _.map(gymArea.distribution, (d) =>
    _.omit(
      {
        ...d,
        characteristics: (d.characteristics || []).map((ch) =>
          _.omit(ch, "__typename"),
        ),
      },
      "__typename",
    ),
  )

  gymArea.location = _.omit(gymArea.location, "__typename")

  if (gymArea.oembed3d) {
    gymArea.oembed3d = _.omit(gymArea.oembed3d, ["__typename", "creator"])
  }

  if (!gymArea.hasProblems) {
    gymArea.distribution = _.filter(
      gymArea.distribution,
      (d) => d.climbType !== "problem",
    )
  }

  if (!gymArea.hasRoutes) {
    gymArea.distribution = _.filter(
      gymArea.distribution,
      (d) => d.climbType !== "route",
    )
  }
  let gymAreaToSave = _.chain(gymArea)
    .pick([
      "_id",
      "name",
      "slug",
      "gym",
      "description",
      "picture",
      "canvasFile",
      "model3d",
      "model3dImage",
      "hasProblems",
      "hasRoutes",
      "isArchived",
      "hideChildren",
      "problemConfig",
      "routeConfig",
      "distribution",
      "height",
      "angle",
      "ropework",
      "oembed3d",
      "isAbleToDuplicate",
      "isExcludedFromDistribution",
    ])
    .value()
  gymAreaToSave.height = Number(gymAreaToSave.height ?? 0)
  if (gymArea.oembed3d) {
    gymAreaToSave = _.omit(gymAreaToSave, ["oembed3d.__typename"])
  }
  // convert gym to id if is object
  if (typeof gymAreaToSave.gym === "object") {
    gymAreaToSave.gym = gymAreaToSave.gym._id
  }
  return gymAreaToSave
}

export const cleanMeetup = (meetup) => {
  let meetupToSave = _.pick(meetup, [
    "_id",
    "slug",
    "user",
    "startTime",
    "endTime",
    "activity",
    "minGrade",
    "maxGrade",
    "genders",
    "isRecurring",
    "daysOfWeek",
    "dateToMeet",
    "gym",
    "isCurrentlyAtGym",
  ])
  meetupToSave.user = meetup.user?._id ?? meetup.user
  meetupToSave.gym = meetup.gym?._id ?? meetup.gym
  meetupToSave.daysOfWeek = meetup.isRecurring ? meetup.daysOfWeek : []
  meetupToSave.dateToMeet = !meetup.isRecurring ? meetup.dateToMeet : null
  meetupToSave.genders = meetupToSave.genders?.length
    ? meetupToSave.genders
    : genderOptions.map((obj) => obj.value)
  // make sure times are same date if not recurring
  if (!meetup.isRecurring) {
    const dateDJS = dayjs(meetup.dateToMeet)
    const startDJS = dayjs(meetup.startTime)
    const endDJS = dayjs(meetup.endTime)
    meetupToSave.startTime = dateDJS
      .hour(startDJS.hour())
      .minute(startDJS.minute())
      .toDate()
    meetupToSave.endTime = dateDJS
      .hour(endDJS.hour())
      .minute(endDJS.minute())
      .toDate()
  }
  // TODO: make sure startTime and endTime are on the create date if one
  return meetupToSave
}

export const cleanComp = (comp) => {
  let compToSave = _.chain(comp)
    .pick([
      "name",
      "slug",
      "gymId",
      "gymIds",
      "picture",
      "badge",
      "description",
      "rules",
      "registrationMessage",
      "canvasFile",
      "badgeId",
      "picture",
      "isTeamComp",
      "minNumberOnTeam",
      "maxNumberOnTeam",
      "registrationInstructions",
      "startDate",
      "endDate",
      "beginRegistrationDate",
      "endRegistrationDate",
      "registrationURL",
      "isDroppingRound",
      "isAdditionalSendsBreakingTies",
      "isAllowingFloaters",
      "isRelativeScoringEnabled",
      "isScoringRoutes",
      "isScoringProblems",
      "categories",
      "isTeamCategory",
      "cost",
      "stripeConnectedAccountId",
      "paymentType",
      "isChargingTeam",
      "isHidden",
      "isInviteOnly",
      "numClimbsBeforeBump",
      "isShowingOverallScores",
      "registrationCap",
      "earlyBirdAmountOff",
      "earlyBirdAmountOffDate",
      "gymMemberCost",
      "timeSlots",
      "discountedPrices",
      "canBypassRegistration",
      "livestreamOembed",
      "isShowingHowToJudge",
      "isShowingHowToPeerScore",
      "howToScoreYTID",
      "howToScoreYTLink",
      "rewardActivityId",
      "referralActivityId",
      "isUsingScanners",
      "isAutoJoining",
      "isHidingLeaderboard",
      "categoryCosts",
      "isHidingCompInMobileTab",
      "isHidingCompRules",
    ])
    .value()
  // for apps that aren't updated yet
  compToSave.cost =
    compToSave.categoryCosts?.length > 0
      ? Math.max(...compToSave.categoryCosts.map((obj) => obj.cost))
      : Number(compToSave.cost)
  compToSave.categoryCosts = (comp.categoryCosts ?? []).map((obj) => ({
    ..._.omit(obj, ["__typename"]),
    cost: Number(obj.cost),
  }))
  compToSave.numClimbsBeforeBump = Number(compToSave.numClimbsBeforeBump)
  // add judges if any
  compToSave.judgeIds = (comp.judges ?? []).map((j) => j._id)
  // users to notify of competitors getting bumped
  compToSave.usersToNotifyOfBumpIds = (comp.usersToNotifyOfBumps ?? []).map(
    (j) => j._id,
  )
  // add gym Id if need one (deprecated)
  if (!compToSave.gymId && !!comp.gym) {
    compToSave.gymId = comp.gym._id
  }
  // add gym ids
  if (!compToSave.gymIds) {
    compToSave.gymIds = comp.gyms.map((g) => g._id)
  }
  // remove typenames from questions
  compToSave.competitorQuestions = (comp.competitorQuestions || []).map(
    (question) =>
      _.pick(question, [
        "_id",
        "title",
        "questionType",
        "options",
        "isRequired",
        "isYesRequired",
      ]),
  )
  compToSave.discountedPrices = (comp.discountedPrices || []).map((discount) =>
    _.pick(discount, [
      "_id",
      "name",
      "description",
      "price",
      "expirationDate",
      "code",
      "numberAvailable",
      "isForMembers",
      "categories",
    ]),
  )
  compToSave.livestreamOembed =
    compToSave.livestreamOembed &&
    _.omit(compToSave.livestreamOembed, ["__typename"])
  compToSave.registrationCap = Number(compToSave.registrationCap) ?? 0
  compToSave.timeSlots = (comp.timeSlots ?? []).map((slot) => ({
    _id: slot._id,
    name: slot.name,
    max: Number(slot.max),
  }))

  return compToSave
}

export const cleanSurvey = (survey) => {
  let surveyToSave = _.chain(survey)
    .pick([
      "_id",
      "name",
      "description",
      "questions",
      "canvasFile",
      "picture",
      "isPublic",
      "isLinkingMRP",
      "participantCount",
      "participantType",
      "expirationDate",
      "memberRewardCode",
    ])
    .value()
  surveyToSave.invitedUserIds =
    survey.invitedUserIds ?? (survey.invitedUsers ?? []).map((user) => user._id)
  surveyToSave.gymIds = (survey.gyms ?? []).map((gym) => gym._id)
  surveyToSave.questions = (survey.questions ?? []).map((question) => {
    const ques = _.pick(question, [
      "_id",
      "title",
      "questionType",
      "options",
      "isRequired",
    ])
    ques.parentQuestionId =
      question.parentQuestion?._id || question.parentQuestionId
    ques.parentOptionId = question.parentOption?._id || question.parentOptionId
    ques.options = (question.options ?? []).map((option) =>
      _.pick(option, [
        "_id",
        "label",
        "value",
        "isAddingTextInput",
        "inputPlaceholder",
      ]),
    )
    return ques
  })
  return surveyToSave
}

export const cleanSurveyAnswers = (surveyAnswers) => {
  let surveyAnswersToSave = surveyAnswers.map((surveyAnswer) => {
    const ans = _.chain(surveyAnswer)
      .pick(["_id", "questionId", "textAnswer"])
      .value()
    ans.optionAnswers = (surveyAnswer.optionAnswers ?? []).map((option) =>
      _.pick(option, ["value", "extraText", "optionId"]),
    )
    return ans
  })
  return surveyAnswersToSave
}

export const cleanCompSetClimbs = (compSetClimbs) => {
  let compSetClimbsToSave = compSetClimbs.map((compSetClimb) => {
    const climb = _.chain(compSetClimb)
      .pick(["_id", "name", "maxCompScores", "numHolds"])
      .value()
    climb.compSetIds = (compSetClimb.compSets ?? []).map((compSet) =>
      compSet._id ? compSet._id : compSet,
    )
    return climb
  })
  return compSetClimbsToSave
}

export const cleanSponsor = (sponsor) => {
  let sponsorToSave = _.chain(sponsor)
    .pick([
      "_id",
      "name",
      "description",
      "image",
      "link",
      "linkText",
      "startDate",
      "endDate",
      "gameTypes",
      "climbTypes",
      "canvasFile",
    ])
    .value()
  sponsorToSave.gymIds = (sponsor.gyms ?? []).map((gym) => gym._id)
  sponsorToSave.guideIds = (sponsor.guides ?? []).map((guide) => guide._id)
  return sponsorToSave
}

export const cleanTopoMap = (topoMap) => {
  const topoMapToSave = _.chain(topoMap)
    .pick([
      "_id",
      "currentPosition",
      "currentScale",
      "currentScalePosition",
      "traceImages",
      "climbingWalls",
      "buildingWalls",
      "overhangingWalls",
      "texts",
      "gymAreaRects",
    ])
    .value()
  topoMapToSave.traceImages = topoMapToSave.traceImages
    ? topoMapToSave.traceImages.map((traceImage) => {
        return _.pick(traceImage, ["url", "posX", "posY", "scale", "rotation"])
      })
    : []
  topoMapToSave.climbingWalls = topoMapToSave.climbingWalls
    ? topoMapToSave.climbingWalls.map((wall) => {
        return {
          ..._.pick(wall, [
            "_id",
            "name",
            "color",
            "posX",
            "posY",
            "scale",
            "rotation",
            "strokeWidth",
            "fill",
          ]),
          points: wall.points.map((point) => {
            return _.omit(point, ["__typename"])
          }),
          startPoint: _.omit(wall.startPoint, ["__typename"]),
        }
      })
    : []
  topoMapToSave.buildingWalls = topoMapToSave.buildingWalls
    ? topoMapToSave.buildingWalls.map((wall) => {
        return {
          ..._.pick(wall, [
            "_id",
            "name",
            "color",
            "posX",
            "posY",
            "scale",
            "rotation",
            "strokeWidth",
            "fill",
          ]),
          points: wall.points.map((point) => {
            return _.omit(point, ["__typename"])
          }),
          startPoint: _.omit(wall.startPoint, ["__typename"]),
        }
      })
    : []
  topoMapToSave.overhangingWalls = topoMapToSave.overhangingWalls
    ? topoMapToSave.overhangingWalls.map((wall) => {
        return {
          ..._.pick(wall, [
            "_id",
            "name",
            "color",
            "posX",
            "posY",
            "scale",
            "rotation",
            "strokeWidth",
            "fill",
          ]),
          points: wall.points.map((point) => {
            return _.omit(point, ["__typename"])
          }),
          startPoint: _.omit(wall.startPoint, ["__typename"]),
        }
      })
    : []
  topoMapToSave.gymAreaRects = topoMapToSave.gymAreaRects
    ? topoMapToSave.gymAreaRects.map((rect) => {
        const gymArea = rect.gymArea?._id
        return {
          ..._.pick(rect, ["posX", "posY", "width", "height"]),
          gymArea,
        }
      })
    : []
  topoMapToSave.texts = topoMapToSave.texts
    ? topoMapToSave.texts.map((text) => {
        return {
          ..._.omit(text, ["__typename"]),
        }
      })
    : []
  topoMapToSave.currentPosition = _.omit(topoMapToSave.currentPosition, [
    "__typename",
  ])
  topoMapToSave.currentScalePosition = _.omit(
    topoMapToSave.currentScalePosition,
    ["__typename"],
  )
  return topoMapToSave
}
